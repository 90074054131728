<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <classroom-archive-datatable/>
      </div>
    </div>
  </div>
</template>

<script>

import ClassroomArchiveDatatable from "@/pages/Didactics/datatables/ClassroomArchiveDatatable";
import IndexClassroomArchivePageHelper from "@/components/Helpers/IndexClassroomArchivePageHelper";
import {mapMutations} from "vuex";

export default {
  name: "IndexClassroomArchivePage",
  components: {ClassroomArchiveDatatable},
  data() {
    return {
      helperComponent: IndexClassroomArchivePageHelper
    }
  },
  mounted() {
    this.setHelper(this.helperComponent);
  },
  beforeDestroy() {
    this.setHelper();
  },
  methods: {
    ...mapMutations({
      setHelper: 'common/setHelper',
    }),
  }
}
</script>

<style scoped lang="scss">

</style>

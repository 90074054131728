<template>
  <octo-table
    show-filters
    enable-export
    :action="endpoints.DATATABLES.classrooms"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {}"
  >
    <template v-slot:status="data">
      <badge :type="data.row.status | classroomStatusColor" class="text-uppercase">
        {{ $t('didactics.classroom_status.' + data.row.status) }}
      </badge>
    </template>

    <template v-slot:type="data">
      <badge :type="data.row.type | classroomTypeColor" class="text-uppercase">
        {{ data.row.type }}
      </badge>
    </template>

    <template v-slot:lessons="data">
      <lessons-container :lessons="data.row.lessons"/>
    </template>

    <template v-slot:location="data">
      <div class="text-uppercase">{{ getLocationName(data.row.location_id) }}</div>
    </template>

    <template v-slot:first_lesson="data">
      {{ data.row | optional('firstLesson.date') | date }}
    </template>

    <template v-slot:latest_lesson="data">
      {{ data.row | optional('latestLesson.date') | date }}
    </template>

    <template v-slot:tutors="data">
      <div v-for="(tutor, index) in data.row.tutors" v-bind:key="`tutor-${index}`" class="small">
        {{ users[tutor.id] | optional('lastname') }}
        {{ users[tutor.id] | optional('firstname') }}
      </div>
    </template>

    <template v-slot:schedulation="data">
      <div class="small">
        {{ data.row.schedulation }}
      </div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <base-button class="mx-1" link size="sm" icon @click="redirectToClassroom(data.row)">
          <octo-icon icon="right-arrow"/>
        </base-button>
      </div>
    </template>

    <template slot="custom-filters">
      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.status')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.statuses"
          >
            <el-option
              v-for="status in statuses"
              class="select-default text-uppercase"
              :value="status"
              :label="$t('didactics.classroom_status.' + status)"
              :key="status"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.type')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.types"
          >
            <el-option
              v-for="type in types"
              class="select-default text-uppercase"
              :value="type"
              :label="$t('common.' + type)"
              :key="type"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('fields.location')" class="mb-0">
          <el-select
            class="select-default text-uppercase"
            filterable
            clearable
            multiple
            :placeholder="$t('common.select')"
            v-model="filters.locationIds"
          >
            <el-option
              class="select-default text-uppercase"
              v-for="(location, index) in locations"
              :value="location.id"
              :label="location.alias"
              :key="`location-${index}`"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-6" v-if="isTeachingManager">
        <base-input :label="$t('datatable.tutors')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.tutorIds"
          >
            <el-option
              v-for="option in tutorOptions"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-6" v-if="isTeachingManager">
        <base-input :label="$t('datatable.first_lesson')">
          <el-date-picker
            v-model="filters.rangeFirstLesson"
            type="daterange"
            align="right"
            unlink-panels
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="$t('fields.date_start')"
            :end-placeholder="$t('fields.date_end')"
            :picker-options="{firstDayOfWeek: 1}"
          />
        </base-input>
      </div>
      <div class="col-12">
        <base-input :label="$t('datatable.courses')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.courseIds"
          >
            <el-option
              v-for="course in courses"
              class="select-default text-uppercase"
              :value="course.id"
              :label="course.code + ': ' + course.title"
              :key="course.id"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import {Select, Option, DatePicker} from "element-ui";
import {mapGetters} from "vuex";
import {permissionService} from "@/util/permission-service";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import LessonsContainer from "@/pages/Didactics/components/LessonsContainer";
import ClassroomStatuses from "@/pages/Didactics/resources/classroomStatuses";
import ClassroomTypes from "@/pages/Didactics/resources/classroomTypes";

export default {
  name: "ClassroomArchiveDatatable",
  components: {
    LessonsContainer,
    LabelThemeComponent,
    Badge,
    OctoIcon,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      name: 'ClassroomArchiveDatatable',
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'code', label: 'code', width: 200, fixed: true},
        {prop: 'status', label: 'status', width: 200, slot: true, align: 'center'},
        {prop: 'type', label: 'type', slot: true, align: 'center', width: 150},
        {prop: 'schedulation', label: 'schedulation', slot: true, width: 250},
        {prop: 'location', label: 'location', slot: true, width: 200},
        {prop: 'lessons', label: 'lessons', slot: true, align: 'center'},
        {prop: 'first_lesson', label: 'first_lesson', slot: true, align: 'center', width: 150},
        {prop: 'latest_lesson', label: 'latest_lesson', slot: true, align: 'center', width: 150},
        {prop: 'tutors', label: 'tutors', width: 200, slot: true},
        {prop: 'actions', label: 'actions', width: 60, slot: true, fixed: 'right'},
      ],
      types: [
        ClassroomTypes.course.value,
        ClassroomTypes.certificate.value,
        ClassroomTypes.workshop.value,
      ],
      permissionService: permissionService
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      locations: 'common/locations',
      courses: 'common/allCourses',
      getFilters: 'common/datatableFilters',
    }),

    tutorOptions() {
      const tutorUsers = this.$_.filter(this.users, user => {
        return this.$_.find(user.roles, role => {
          return role.name === permissionService.TUTOR_ROLE;
        })
      });

      return this.$_.map(tutorUsers, user => {
        return {
          label: (user?.lastname + ' ' + user?.firstname) || user.username,
          value: user.id
        }
      });
    },

    isTeachingManager() {
      return permissionService.isAllowed([permissionService.TEACHING_MANAGER_ROLE]);
    },

    statuses() {
      const statuses = [
        ClassroomStatuses.on_going.value,
        ClassroomStatuses.interrupted.value,
        ClassroomStatuses.closed.value,
      ];

      if (this.isTeachingManager) {
        statuses.push(
          ClassroomStatuses.pending.value,
          ClassroomStatuses.scheduled.value,
          ClassroomStatuses.ready_to_go.value
        );
      }

      return statuses;
    },
  },
  methods: {
    redirectToClassroom(classroom) {
      if (
        this.$_.includes(
          [ClassroomStatuses.on_going.value, ClassroomStatuses.closed.value, ClassroomStatuses.interrupted.value],
          classroom.status
        )
      ) {
        if (classroom.type === ClassroomTypes.certificate.value) {
          this.$router.push({name: 'didactics.classrooms.archive.certificate.show', params: {id: classroom.id}});
        } else {
          this.$router.push({name: 'didactics.classrooms.on-going.show', params: {id: classroom.id}});
        }
      } else {
        this.$router.push({name: 'didactics.classrooms.pending.show', params: {id: classroom.id}});
      }
    },

    getLocationName(locationId) {
      return this.$_.find(this.locations, location => location.id === locationId)?.alias;
    }
  }
}
</script>

<style scoped>

</style>

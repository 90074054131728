<template>
  <div>
    <p class="text-uppercase">{{ $t('common.status') }}</p>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('didactics.classroom_status.' + classroomStatuses.pending.value)">
        <div>
          Stato iniziale di tutte le aule, sono ancora da compilare dati come docenti, studenti e le lezioni dell'aula.
        </div>
      </collapse-item>
      <collapse-item :title="$t('didactics.classroom_status.' + classroomStatuses.scheduled.value)">
        <div>
          Una volta aggiunte le lezioni di un'aula, arriva in questo stato. È ancora possibile aggiornare
          docenti, studenti e le lezioni dell'aula.
        </div>
      </collapse-item>
      <collapse-item :title="$t('didactics.classroom_status.' + classroomStatuses.ready_to_go.value)">
        <div>
          Stato che indica che i dati necessari per partire sono stati compilati e che l'aula è pronta
          per essere attivata.
        </div>
      </collapse-item>
      <collapse-item :title="$t('didactics.classroom_status.' + classroomStatuses.on_going.value)">
        <div>
          Stato dell'aula dopo l'attivazione, in questo stato si possono compilare le presenze delle lezioni, gli
          esiti delle certficazioni e il programma dell'aula.
        </div>
      </collapse-item>
      <collapse-item :title="$t('didactics.classroom_status.' + classroomStatuses.closed.value)">
        <div>
          Stato dell'aula dopo la chiusura. È possibile aggiornare ancora gli esiti delle certificazioni
          e le presenze. Non è possibile aggiungere più lezioni a meno che non venga rimessa
          {{ $t('didactics.classroom_status.' + classroomStatuses.on_going.value) }}.
        </div>
      </collapse-item>
    </collapse>
  </div>
</template>

<script>
import CollapseItem from "@/components/Collapse/CollapseItem";
import Collapse from "@/components/Collapse/Collapse";
import classroomStatuses from "@/pages/Didactics/resources/classroomStatuses";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "IndexClassroomArchivePageHelper",
  components: {LabelThemeComponent, CollapseItem, Collapse},
  data() {
    return {
      classroomStatuses: classroomStatuses
    }
  }
}
</script>

<style scoped>

</style>
